import React, { forwardRef, useState, useEffect } from "react";
import {
  FormContainer,
  Button,
  Dropdown,
  Dialog,
  toast,
  Notification,
} from "components/ui";
import { StickyFooter } from "components/shared";
import { Form, Formik } from "formik";
import {
  HiArrowRight,
  HiBookOpen,
  HiArchive,
  HiOutlineRefresh,
} from "react-icons/hi";
import { AiOutlineSave } from "react-icons/ai";
import * as Yup from "yup";
import RecipientFields from "./RecipientFields";
import { useDispatch, useSelector } from "react-redux";
import { deleteEntities, getEntities } from "../ProgramsList/store/dataSlice";
import reducer from "../ProgramsList/store";
import { injectReducer } from "store/index";
import { useNavigate } from "react-router-dom";
import {
  openAddNoteDialog,
  setEntityData,
} from "views/donors/DonorDetail/store/stateSlice";
import AddNote from "views/donors/DonorDetail/components/AddNote";
import moment from "moment";
import { apiDeleteEntity, apiRestoreEntity } from "services/ProgramsService";
import PropTypes from "prop-types";

injectReducer("entitiesList", reducer);

const ConfirmationDialog = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  message,
  confirmLabel,
}) => (
  <Dialog isOpen={isOpen} onClose={onClose} onRequestClose={onClose}>
    <h5 className="mb-4">{title}</h5>
    <p>{message}</p>
    <div className="text-right mt-6">
      <Button className="ltr:mr-2 rtl:ml-2" variant="plain" onClick={onClose}>
        Cancel
      </Button>
      <Button variant="solid" onClick={onConfirm}>
        {confirmLabel}
      </Button>
    </div>
  </Dialog>
);

ConfirmationDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  confirmLabel: PropTypes.string.isRequired,
};

const archiveEntityData = async ({
  entity_id,
  client_id,
  showToast,
  entityName,
  setButtonHidden,
  isButtonHidden,
}) => {
  try {
    const success = await apiDeleteEntity({
      entity_id: entity_id,
      client_id: client_id,
    });
    if (success) {
      showToast("Success", `${entityName} successfully archived`, "success");
      setButtonHidden(!isButtonHidden);
    }
  } catch (error) {
    const errorMessage = error?.response?.data?.message || "Error Occurred";
    showToast("Error", errorMessage, "danger");
  }
};

const restoreEntityData = async ({
  entity_id,
  client_id,
  showToast,
  entityName,
  setButtonHidden,
  programState,
  onDiscard,
  programId,
  dispatch,
}) => {
  try {
    const success = await apiRestoreEntity({
      entity_id: entity_id,
      client_id: client_id,
    });
    if (success) {
      showToast("Success", `${entityName} successfully restored`, "success");
      setButtonHidden(true);
      if (programState === "Archived Recipients") {
        onDiscard();
        dispatch(
          getEntities({
            program_id: programId,
            client_id,
            page: "1",
            perpage: "10",
            filter: true,
          })
        );
      }
    }
  } catch (error) {
    const errorMessage = error?.response?.data?.message || "Error Occurred";
    showToast("Error", errorMessage, "danger");
  }
};

const deleteEntityData = async ({
  entity_id,
  programId,
  showToast,
  entityName,
  setButtonHidden,
  onDiscard,
  onReload,
}) => {
  const enitityIds = [];
  enitityIds.push(entity_id);
  try {
    const success = await deleteEntities({
      entity_ids: enitityIds,
      program_id: programId,
    });
    if (success) {
      showToast("Success", `${entityName} successfully deleted`, "success");
      setButtonHidden(true);
      onDiscard();
      onReload();
    }
  } catch (error) {
    const errorMessage = error?.response?.data?.message || "Error Occurred";
    showToast("Error", errorMessage, "danger");
  }
};

const showToast = (title, message, type) => {
  toast.push(
    <Notification title={title} type={type}>
      {message}
    </Notification>,
    {
      placement: "top-center",
    }
  );
};

const RecipientForm = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { onFormSubmit, onDiscard, entity_id, onReload } = props;

  const programState = useSelector(
    (state) => state.entitiesList.state.programState
  );

  const [isButtonHidden, setButtonHidden] = useState(true);
  const [newArray, setNewArray] = useState([]);

  const [restoreDialogIsOpen, setRestoreDialogIsOpen] = useState(false);
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
  const [archiveDialogIsOpen, setArchiveDialogIsOpen] = useState(false);

  const handleRestore = () => {
    restoreEntityData({
      entity_id,
      client_id,
      showToast,
      entityName,
      setButtonHidden,
      programState,
      onDiscard,
      programId,
      dispatch,
    });
    setRestoreDialogIsOpen(false);
  };

  const handleDelete = () => {
    deleteEntityData({
      entity_id,
      programId,
      showToast,
      entityName,
      setButtonHidden,
      onDiscard,
      onReload,
    });
    setDeleteDialogIsOpen(false);
  };

  const handleArchive = () => {
    archiveEntityData({
      entity_id,
      client_id,
      showToast,
      entityName,
      setButtonHidden,
      isButtonHidden,
    });
    setArchiveDialogIsOpen(false);
  };

  const navigate = useNavigate();

  const { client_id } = useSelector((state) => state.auth.user);

  const recipientFields = useSelector(
    (state) => state.entitiesEdit.data.entityData?.fields
  );

  const programId = useSelector(
    (state) => state.entitiesEdit.data.entityData?.profile.program_id
  );

  const submitForms = useSelector(
    (state) => state.entitiesEdit.data.entityData?.submit
  );

  const initialFields = {};

  const extraFields = recipientFields.map((field) => {
    if (field.field_type === "hysDate") {
      const dateFormatsToCheck = ["YYYY-MM-DD", "MM-DD-YYYY", "DD/MM/YYYY"];
      let formattedDate = null;

      for (const format of dateFormatsToCheck) {
        const isValidFormat = moment(field.field_data, format, true).isValid();
        if (isValidFormat) {
          formattedDate =
            format === "YYYY-MM-DD"
              ? field.field_data
              : moment(field.field_data, format).format("YYYY-MM-DD");
          break;
        }
      }

      return {
        [`${field.field_key}`]: formattedDate,
      };
    } else {
      return {
        [`${field.field_key}`]: field.field_data,
      };
    }
  });
  const editFields = Object.assign(initialFields, ...extraFields);

  const sponsorshipData = useSelector(
    (state) => state.entitiesEdit.data.entityData?.entity
  );

  const entityName = useSelector(
    (state) => state.entitiesEdit.data.entityData?.name
  );

  const entityTags = useSelector(
    (state) => state.entitiesEdit.data.entityData?.profile?.tags
  );

  const createValidationSchema = (data) => {
    const schema = {};

    data?.forEach(({ field_key, field_label, required }) => {
      if (required === 1) {
        schema[field_key] = Yup.mixed().required(`${field_label} is required`);
      }
    });

    return Yup.object().shape(schema);
  };

  //useEffect to load the programs
  useEffect(() => {
    let newArray = submitForms.map((item) => ({
      key: item.id,
      name: item.name,
    }));
    setNewArray(newArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validationSchema = createValidationSchema(recipientFields);

  const onNotes = () => {
    dispatch(openAddNoteDialog());
    dispatch(setEntityData(entity_id));
  };

  const handleMoveClick = () => {
    navigate(`/app/entites/move_entity/${entity_id}`, {
      state: {
        data: recipientFields,
        programId: programId,
        childName: entityName,
      },
    });
  };

  const onSearchOptionChange = (eventKey) => {
    navigate(
      `/app/entites/list_archived_forms/entity/${entity_id}/${eventKey}`
    );
  };

  const Toggle = (
    <Button size="sm" className="ltr:mr-3 rtl:ml-3">
      Report
    </Button>
  );

  const dropdownList = [
    {
      label: "Notes",
      value: "notes",
      icon: <HiBookOpen />,
      onClick: onNotes,
    },
    {
      label: "Move",
      value: "move",
      icon: <HiArrowRight />,
      onClick: handleMoveClick,
    },
    {
      label:
        isButtonHidden && programState !== "Archived Recipients"
          ? "Archive"
          : "Restore",
      value:
        isButtonHidden && programState !== "Archived Recipients"
          ? "archive"
          : "restore",
      icon:
        isButtonHidden && programState !== "Archived Recipients" ? (
          <HiArchive />
        ) : (
          <HiOutlineRefresh />
        ),
      onClick: () =>
        isButtonHidden && programState !== "Archived Recipients"
          ? setArchiveDialogIsOpen(true)
          : setRestoreDialogIsOpen(true),
    },
    ...(!isButtonHidden
      ? [
          {
            label: "Delete",
            value: "delete",
            icon: <HiArchive />,
            onClick: () => setDeleteDialogIsOpen(true),
          },
        ]
      : []),
  ];

  const ToggleMoreActions = <Button size="sm">More Actions</Button>;

  const tagFields = entityTags;
  const initialTags = React.useMemo(() => {
    let tags = tagFields;
    if (typeof tags === "string") {
      tags = tags.split(",");
    }
    if (Array.isArray(tags)) {
      return tags.map((tag) => ({ label: tag.trim(), value: tag.trim() }));
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagFields]);

  return (
    <>
      <ConfirmationDialog
        isOpen={restoreDialogIsOpen}
        onClose={() => setRestoreDialogIsOpen(false)}
        onConfirm={handleRestore}
        title="Restore Recipient"
        message={`You are about to restore ${entityName}. If you are sure, please click 'Restore' or click 'Cancel' to close the dialog.`}
        confirmLabel="Restore"
      />

      <ConfirmationDialog
        isOpen={deleteDialogIsOpen}
        onClose={() => setDeleteDialogIsOpen(false)}
        onConfirm={handleDelete}
        title="Delete Recipient"
        message={`You are about to delete ${entityName}. If you are sure, please click 'Delete' or click 'Cancel' to close the dialog.`}
        confirmLabel="Delete"
      />

      <ConfirmationDialog
        isOpen={archiveDialogIsOpen}
        onClose={() => setArchiveDialogIsOpen(false)}
        onConfirm={handleArchive}
        title="Archive Recipient"
        message={`You are about to archive ${entityName}. If you are sure, please click 'Archive' or click 'Cancel' to close the dialog.`}
        confirmLabel="Archive"
      />

      <Formik
        innerRef={ref}
        initialValues={{
          ...editFields,
          ...sponsorshipData,
          client_id: client_id,
          entity_id: entity_id,
          tags: initialTags,
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          delete values["en_test_table"];
          const formData = values;
          Object.keys(formData).forEach((key) => {
            if (Array.isArray(formData[key]) && formData[key].length === 1) {
              formData[key] = formData[key][0];
            } else if (
              Array.isArray(formData[key]) &&
              formData[key].length === 0
            ) {
              if (key === 'tags') {
                delete formData[key]; // This line will remove the 'tags' key from formData if the array is empty
              } else {
                formData[key] = ""; // Maintain existing logic for other array fields
              }
            }
          });

          if (Array.isArray(formData.tags)) {
            formData.tags = formData.tags.map((tag) => tag.value);
          } else if (
            formData.tags &&
            typeof formData.tags === "object" &&
            formData.tags.hasOwnProperty("value")
          ) {
            // If 'tags' is a single object, get the 'value'
            formData.tags = [formData.tags.value];
          }

          onFormSubmit?.(formData, setSubmitting);
        }}
      >
        {({
          touched,
          errors,
          isSubmitting,
          values,
          setFieldValue,
          handleChange,
        }) => (
          <Form>
            <FormContainer>
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                <div className="lg:col-span-3">
                  <RecipientFields
                    touched={touched}
                    errors={errors}
                    values={values}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                  />
                </div>
              </div>
              <StickyFooter
                className="-mx-8 px-8 flex items-center justify-between py-4 flex-wrap"
                stickyClass="border-t bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-700"
              >
                <div className="flex flex-col">
                  <Dropdown
                    placement="top-start"
                    renderTitle={ToggleMoreActions}
                  >
                    {dropdownList.map((item) => (
                      <Dropdown.Item
                        eventKey={item.value}
                        key={item.value}
                        onSelect={() => item.onClick()}
                      >
                        <span className="text-lg">{item.icon}</span>
                        <span className="ml-2 rtl:mr-2">{item.label}</span>
                      </Dropdown.Item>
                    ))}
                  </Dropdown>
                  <div className="md:flex items-center mt-2">
                    <Dropdown renderTitle={Toggle} placement="top-start">
                      {newArray?.map((item) => (
                        <Dropdown.Item
                          eventKey={item.key}
                          key={item.key}
                          onSelect={onSearchOptionChange}
                        >
                          {item.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown>
                    <Button
                      size="sm"
                      className="ltr:mr-3 rtl:ml-3"
                      onClick={() => onDiscard?.()}
                      type="button"
                    >
                      Discard
                    </Button>
                    <Button
                      size="sm"
                      variant="solid"
                      loading={isSubmitting}
                      icon={<AiOutlineSave />}
                      type="submit"
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </StickyFooter>
              <AddNote />
            </FormContainer>
          </Form>
        )}
      </Formik>
    </>
  );
});

RecipientForm.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
  onDiscard: PropTypes.func,
  entity_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onReload: PropTypes.func,
  // Include other props here as necessary
};

export default RecipientForm;
